import React from "react"

import { footnote, footlink } from "../shared/links"

import styles from "../../styles/main.module.css"

export default (props) =>  {
    const footnotes = [
        `If you look at the back cover where the reviews are, you'll notice Nassim Taleb extolling this fact (that he is a practictioner), which I cannot help but find funny. Go read some of his works from the Incerto for context. Also, all the reviewers seem to be Thiel's buddies. Hmm...`,
        `Keep in mind that this book is based on a lecture Peter gave in 2012 for a Stanford Entrepeneurship class (so it is necessarily practical more so than theoretical). I recommend "https://startupclass.samaltman.com/" for those interested.`,
        `Disclaimer: you can probably cover all the core ideas by watching the startup class I linked in the previous footnote and perhaps watching some interviews Thiel gives. There are advantages to books as a format (I like to write in the margins, for example) but if you're strapped for cash or prefer videos to books, you should be fine with online resources.`,
        `True contrarianism is not about going contrary to popular opinion. It is about thinking for yourself (in this own words). Of course, this tends to matter most when our beliefs are contrary to what is popular.`,
        `Since (approximately) the 80's, Thiel believes that we have not progressed that much (technologically) outside of IT. It's hard for me to understand the extent of this fact since I know primarily about tech.`,
        `...which they then use to transfer wealth in their own pockets.`,
        `Interestingly, monopolies try to play themselves off as being in competitive markets, though firms in perfect competition try to play themselves off as closer to monopolies. Consider google vs. a restaurant.`,
        `After examining our society, Thiel characterizes our views of the future in four camps: determistic optimistic (US, 50's/60's), deterministic pessimistic (China, present), non-deterministic optimistic (US, present), and non-deterministic pessimistic (Europe, present). His commentary is worth reading.`,
    ]

    return (
        <article>
            <h2>A Practical Book</h2>
            <hr className={styles.top_seperator} />
            <section id="body-section">
                <p>In <i>Zero to One</i>, Peter Thiel presents the broad strokes for his philosophy on innovation, technology, and entrepeneurship. As he is a practitioner {footlink(1)}, this is a worthwhile read. However, do not expect him to provide thorough arguments on his views: he presents his basic framework bundled with no more than some case studies and personal stories. Its correctness is left to be verified primarily by reality more so than argumentation {footlink(2)}. I do not entirely agree with his ideas, but I trust that his primary assertions are the way to go. The more interesting parts of <i>Zero to One</i> usually involve the case studies, with the straightforward, somewhat dry, writing style taking away from the reading experience, but I would nonetheless encourage you to read Thiel's work as it not only provides food for thought and useful heuristics, but it is also short and easy to understand: you could finish it in a day {footlink(3)}.</p>
                <p>Thiel loves contrarian views {footlink(4)}. He frequently presents the question &quot;what truth very few people agree with you on?&quot; to the reader. Every good business is founded upon such a a core truth which few people know about: a contrarian view, also known as a <b>secret</b>. &quot;Every moment in business only happens once, &quot; so future innovation will not stem from the creation of a new operating system, browser, or smartphone. For our fair nation to continue innovating it is crucial not that we focus on replicating what works in increasingly efficient ways, what Thiel characterizes as <b>1 to n</b>, but that we build where there was nothing, <b>0 to 1</b>. This is a fundamental split. We can characterize the creative forces in the world today in these terms: according to Thiel, 1 to n generally describes globalization, and 0 to 1 describes technology. To ensure a positive future we must focus on 0 to 1 more so than 1 to n, and unfortunately, despite appearances, we are currently doing the opposite {footlink(5)}.</p>
                <p>One key tool of innovation is monopoly. By &quot;monopoly&quot; Thiel refers not to rent-seekers, or anti-competitive cronies, but to companies who, in making a 0 to 1 jump, swallow an entire market. Examples include google (search), Apple (iphone), and Tesla (viable electric cars and energy); they do <b>not</b> include big banks and old-world titans with no skin in the game and all the redistributive powers of globalization at their disposal {footlink(6)}. Much of the book discuses how to form a monopoly with a 0 to 1-style company. Network effects, economies of scale, proprietary technology, and branding are the key tools of the innovative monpolist. I will not dive into details, but his teachings should ring a bell to anyone with some econ 101 knowledge.</p>
                <p>A more interesting observation Thiel makes is regarding our culture: we are <b>obsessed</b> with competition. We are taught from a young age that capitalism means competition, and competition is crucial for a vibrant nation {footlink(7)}. We are taught in school to compete for acadmic prizes (1 to n). Our obsession with stocks and efficient markets too is about <b>competition</b>. However, purely competitive capitalism cannot bear fruit in a 0 to 1 world. As markets careen towards perfect competition we may recieve more polished products, but no one firm can innovate since all their spare time is taken up trying to keep the lights on. I hesitate to criticise a culture of competition so fully since I do believe that competition is a crucial part of the lifecycle of progress. In my opinion, monopolies should be encouraged early on in the lifecycle to allow for 0 to 1 progress. As the technology becomes stale, competition should not be artificially witheld from markets. A tool that currently exists (though perhaps not anymore in its golden era) that does this is the patent. Nonetheless, Thiel is right on the money in our current context.</p>
                <p>Another observation I liked was that regarding our lack of planning. We used to plan for up to at least a decade in the future (consider the Apollo missions). Great progress was possible, because we were not lacking planning. The new gold standard seems to be diversification, but diversification cannot deliver 0 to 1 innovation, only 1 to n efficiencies, only comfort and safety in a world headed towards stagnation. Thiel's advice is clear: your life is not a lottery ticket and nor is any group's, it is often better to devote yourself to one goal, rather than strive to keep &quot;your options open.&quot; I do not entirely agree, as there are many cases where the "diversification" approach makes sense (consider medium-term planning for traders). In the proper cases (which is most), however, Thiel is right {footlink(8)}.</p>
                <p>The last point I'll explore is regarding erroneous lessons Thiel believes we learned from the dot-com bubble. Thiel sees that from our failures in the dot-com bubble we learned to make incremental advances, keep companies lean and flexible (focusing on iteration and market research), improve on the competition, and focus on product over sales (good products attract customers). These truths are fundamentally wrong, he believes. It's better to &quot;risk boldness than triviality,&quot; have a plan, avoid competitive markets like the plague (they kill profits and stifle 0 to 1 innovation), and focus on sales as much as product. Our hubris got us shot down real hard and now we've careened too far in the opposite direction. I had not thought about the potential erroneous cultural lessons learned from previous crises. It is worth exploring further.</p>
                <p>The rest of Thiel's book discusses various case studies and heuristics for startups. I recommend the &quot;seeing green&quot; section in chapter fourteen. Overall, I'd recommend this book to anyone aiming to become an entrepeneur as well as anyone who is willing to listen to unsusbstantiated argumentation and weigh it on its emperical merits.</p>
            </section>
            <section id="footnotes-section">
                {footnotes.map((note, index) => footnote(index + 1, note))}
            </section>
            <section>
                <div className={styles.phone_home}>
                <a href="/">Click me to return home.</a>
                </div>
            </section>
        </article>
    )
}